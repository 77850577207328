import { $ } from 'react-jquery-plugin'

var jQueryScript = document.createElement('script');
jQueryScript.setAttribute('src', 'https://code.jquery.com/jquery-3.6.4.js');
document.head.appendChild(jQueryScript);

setTimeout(function () {
  // Add the rest of your code here, as we have to wait a moment before the document has jQuery as a part of it.
  // var $ = jquery;
  $(document).ready(function () {
    $("#lightThemeToggleBtnIndex").click(() => {
      $("body").toggleClass("dark-mode-body");
      // img = $('#lightThemeToggleBtnIndex img').attr("src") - useful comment
      var src =
        $("#lightThemeToggleBtnIndex img").attr("src") ===
          require("./assets/images/dark_theme_toggle_vector.png")
          ? require("./assets/images/light_theme_toggle_vector.png")
          : require("./assets/images/dark_theme_toggle_vector.png");
      $("#lightThemeToggleBtnIndex img").attr("src", src);
    });
      
    //cookie event start 
    if (document.cookie.indexOf("accepted_cookies=") < 0) {
      $('.cookieModal').modal('show');
    }
    $('#cookie-allow').on('click', function () {
      const now = new Date();
      const currentTime = now.getTime();
      const expireTime = currentTime + 1000 * 60 * 60 * 24 * 365;
      // one year
      now.setTime(expireTime);
      document.cookie = 'accepted_cookies=yes;expires=' + now.toUTCString() + ';';
      $('.cookieModal').modal('hide');
    })

    $('#cookie-decline').on('click', function () {
      $('.cookieModal').modal('hide');
    })
    //cookie event end

    // technology toggle code start
    // All container id
    var webCon = $('#web-tech-container')
    var backCon = $('#back-tech-container')
    var databaseCon = $('#database-tech-container')
    var cloudCon = $('#cloud-tech-container')
    var otherCon = $('#other-tech-container')
    var testingCon = $('#testing-tech-container')
    var aiCon = $('#ai-tech-container')

    var allTechs = [webCon, cloudCon, databaseCon, otherCon, backCon, testingCon, aiCon]

    databaseCon.hide()
    webCon.hide()
    otherCon.hide()
    backCon.hide()
    testingCon.hide()
    aiCon.hide()

    var tech
    var btn
    for (tech of allTechs) {
    }
    function changeTabContent(idnm) {
      for (tech of allTechs) {
        if (tech.attr('id') === idnm) {
          $(`#` + idnm).show()
        }
        else (
          tech.hide()
        )
      }
    }

    // All btn ids
    var webBtn = $('#web-tech-btn')
    var backBtn = $('#back-tech-btn')
    var databaseBtn = $('#database-tech-btn')
    var cloudBtn = $('#cloud-tech-btn')
    var otherBtn = $('#other-tech-btn')
    var testingBtn = $('#testing-tech-btn')
    var aiBtn = $('#ai-tech-btn')

    var allBtn = [webBtn, databaseBtn, cloudBtn, otherBtn, backBtn, testingBtn, aiBtn]

    for (btn of allBtn) {
    }
    function changeActiveClass(idnm) {
      for (btn of allBtn) {
        if (btn.attr('id') === idnm) {
          $(`#` + idnm).addClass('active')
        }
        else (
          btn.removeClass('active')
        )
      }
    }

    $('#web-tech-btn').mouseover(() => {
      changeActiveClass("web-tech-btn")
      changeTabContent("web-tech-container")
    })

    $('#back-tech-btn').mouseover(() => {
      changeActiveClass("back-tech-btn")
      changeTabContent("back-tech-container")
    })

    $('#cloud-tech-btn').mouseover(() => {
      changeActiveClass("cloud-tech-btn")
      changeTabContent("cloud-tech-container")
    })

    $('#database-tech-btn').mouseover(() => {
      changeActiveClass("database-tech-btn")
      changeTabContent("database-tech-container")
    })

    $('#other-tech-btn').mouseover(() => {
      changeActiveClass("other-tech-btn")
      changeTabContent("other-tech-container")
    })

    $('#testing-tech-btn').mouseover(() => {
      changeActiveClass("testing-tech-btn")
      changeTabContent("testing-tech-container")
    })

    $('#ai-tech-btn').mouseover(() => {
      changeActiveClass("ai-tech-btn")
      changeTabContent("ai-tech-container")
    })
    // technology toggle code end

    // laptop on hover image change start
    var softwareLpBtn = $('#software-dev-link')
    var mobileLpBtn = $('#mobile-lp-btn')
    var designLpBtn = $('#design-lp-btn')

    var allLpBtn = [softwareLpBtn, mobileLpBtn, cloudBtn, designLpBtn]

    function changeImgOnHover(btnlpid, imgname) {
      $(`#` + btnlpid).mouseover(() => {
        $("#laptop-img-id").attr("src", imgname);
      })
    }

    changeImgOnHover('software-dev-link', require("./assets/images/laptop_frame_1.png"))
    changeImgOnHover('mobile-lp-btn', require("./assets/images/laptop_frame_2.png"))
    changeImgOnHover('cloud-lp-btn', require("./assets/images/laptop_frame_3.png"))
    changeImgOnHover('design-lp-btn', require("./assets/images/laptop_frame_4.png"))
    // laptop on hover image change end
  })
  $(document).ready(function () {
    //tabs
    var managementteamcon = $('#management-team-container')
    var teammemcon = $('#expert-team-container')

    var allJobs = [managementteamcon, teammemcon]

    var job
    for (job of allJobs) {
    }
    function changeTabContent(idnm) {
      for (job of allJobs) {
        if (job.attr('id') === idnm) {
          $(`#` + idnm).show()
        }
        else (
          job.hide()
        )
      }
    }

    // btns
    var viewAll = $('#view-all')
    var managementteambtn = $('#management-team-btn')
    var teammembtn = $('#expert-team-btn')

    var allBtn = [viewAll, managementteambtn, teammembtn]

    var Btn
    for (Btn of allBtn) {
    }
    function changeActiveClass(idnm) {
      for (Btn of allBtn) {
        if (Btn.attr('id') === idnm) {
          $(`#` + idnm).addClass('active')
        }
        else (
          Btn.removeClass('active')
        )
      }
    }
    teammemcon.hide()
    //change tabs
    $('#view-all').mouseover(() => {
      changeActiveClass("view-all")
      managementteamcon.show()
      teammemcon.show()
    })

    $('#management-team-btn').mouseover(() => {
      changeActiveClass("management-team-btn")
      changeTabContent("management-team-container")
    })

    $('#expert-team-btn').mouseover(() => {
      changeActiveClass("expert-team-btn")
      changeTabContent("expert-team-container")
    })
  })

}, 100);