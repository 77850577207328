import React from "react";
import ReactHelmetTag from "../../CommonComponents/ReactHelmet/ReactHelmetTag";
import Allprojects from "../../CommonComponents/Userguide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const LeaveMSGuide = () => {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/leave-management-system"
        metaKeywords="Introducing leave management system: a powerful solution designed to revolutionize the way organizations handle employee leaves."
      />
      <section>
        <LazyLoadComponent>
          <Allprojects project="Leave Management System" path="learning-management-portal"/>
        </LazyLoadComponent>
      </section>
    </div>
  );
};

export default LeaveMSGuide;
