import React from 'react'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag';
import Allprojects from '../../CommonComponents/Userguide/Allprojects';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const AMSGuide = () => {
    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/guidelines/asset-management-system"
                metaKeywords="Asset management system guidelines, Asset management system installation steps, Asset management system help center, Sharepoint asset management system"
            />

            <section>
                <LazyLoadComponent>
                    <Allprojects project="Asset Management System" path="/asset-management-system"/>
                </LazyLoadComponent>
            </section >
        </div >
    )
}

export default AMSGuide