import React from 'react';
import { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import { Link } from 'react-router-dom/dist';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Allproducts.css';
import Footer from '../../Footer/Footer';
import ClientsAcrossTheGlobe from '../../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe';
import ProductOverview from '../ProductPage/ProductOverview';
import Magamenu from '../../Navbar/Megamenu';

export default function Allproducts() {
    const [theme, setTheme] = useLocalStorage('theme', 'light');

    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    useEffect(() => {
        document.title = "Products";
        window.scrollTo(0, 0);
    }, []);

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    };

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
                <video id='myVideo' height="100%" width="100%" autoPlay loop muted playsInline preload="auto" className='backimgproducts'>
                    <source src={require('../../../assets/videos/LandingPageVideo/sharepointEmpower-2.mp4')} type='video/mp4' />
                </video>
            </div>
            <Magamenu />
            <div className='allproductpage'>
                <div className='d-flex flex-row container justify-content-between mt-8'>
                    <div>
                        <h3 className=' allfirst-section-margin-top-for-navbar' style={{ fontFamily: 'Open Sans', fontWeight: '600' }}>Explore Our M365 Certified & Modern SharePoint Framework (SPFx) Solutions</h3>
                    </div>
                    <div className="light-mode-title productquotationbtn d-flex align-items-center pr-4">
                        <Link to="/faqs" className='pricingfaq'>
                            <i className="fa fa-circle-question mr-1" />
                            <span className='m-0'>FAQs</span>
                        </Link>
                    </div>
                </div>

                <ProductOverview />
                <ClientsAcrossTheGlobe />
                <Footer />

            </div>

        </>
    );
}
