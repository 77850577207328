import React from 'react'
import { Helmet } from "react-helmet";

function ReactHelmetTag(props) {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href={props.link} />
                <meta name="title" content={props.metaTitle} />
                <meta name="description" content={props.metaDescription} />
                <meta name="keywords" content={props.metaKeywords} />
            </Helmet>
        </React.Fragment>
    )
}

export default ReactHelmetTag