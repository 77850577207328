import React, { useEffect, useContext } from 'react'
import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext'
import Magamenu from '../Navbar/Megamenu'

function SiteMap() {

  const { apiProductPagedata } = useContext(ApiDataContext);


  useEffect(() => {
    document.title = "Site map";
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/site-map"
      />

      <Magamenu />
      <div className='mt-7'>
        <PageHeadingContent heading="Sitemap" />
      </div>

      <div className="container">
        <div className="before-row-margin-lm"></div>
        <div className="row">
          <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
            <table className="table project-feature-ul-anim ">
              <tbody>
                <tr>
                  <th><Link to="/"><a><h4>Home page</h4></a></Link></th>
                </tr>
                <tr>
                  <th><a href="https://blog.sharepointempower.com/" target="_blank"
                    rel="noreferrer noopener">
                    <h4>Blog</h4>
                  </a></th>
                </tr>
                <tr>
                  <th><Link to="/all-products"><h4>Products</h4></Link></th>
                </tr>
                <tr>
                  <th><Link to="/Order-page"><h4>Order Page</h4></Link></th>
                </tr>
                <tr>
                  <th><Link to="/faqs"><h4>FAQs</h4></Link></th>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
            <table className="table project-feature-ul-anim ">
              <tbody>
                <tr>
                  <th>
                    <h4>Products</h4>
                  </th>
                </tr>
                {apiProductPagedata?.map((e) => (
                  e.displayOrder === 0 ? null : (
                    <tr><td><Link to={e?.routePath}><a>{e?.projectTitle}</a></Link></td></tr>
                  )
                ))}
                <tr>
                  <td><Link to="/product-pricing-list">Pricing</Link></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
            <table className="table project-feature-ul-anim ">
              <tbody>
                <tr>
                  <th>
                    <h4>About us</h4>
                  </th>
                </tr>
                <tr>
                  <td><Link to="/">Home page</Link></td>
                </tr>
                <tr>
                  <td><Link to="/about-us">Who we are?</Link></td>
                </tr>
                <tr>
                  <td><Link to="/our-commitment">Our Commitment</Link></td>
                </tr>
                <tr>
                  <td><Link to="/career-at-sharepointempower">Career at SharepointEmpower</Link></td>
                </tr>
                <tr>
                  {/* <td><Link to="/our-team">Our Team</Link></td> */}
                </tr>
                <tr>
                  <td><Link to="/contact-us">Contact us</Link></td>
                </tr>
                <tr>
                  <td><Link to="/find-us">Find us</Link></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col col-12 col-sm-12 col-lg-3 col-md-6 col-xl-3 col-xxl-3">
            <table className="table project-feature-ul-anim ">
              <tbody>
                <tr>
                  <th>
                    <h4>Product Help Center</h4>
                  </th>
                </tr>
                <tr>
                  <td><Link to="/guidelines/asset-management-system/introduction">AMS Guidelines </Link></td>
                </tr>
                <tr>
                  <td><Link to="/guidelines/learning-management-system/introduction">LMP Guidelines </Link></td>
                </tr>
                <tr>
                  <td><Link to="/guidelines/audit-inspection/introduction">Audit Inspection Guidelines </Link></td>
                </tr>
                <tr>
                  <td><Link to="/guidelines/construction-management-system/introduction">CMS Guidelines </Link></td>
                </tr>
                <tr>
                  <td><Link to="/guidelines/spe-intranet/introduction">SPE Intranet Guidelines </Link></td>
                </tr>
                <tr>
                  <td><Link to="/guidelines/helpdesk-system/introduction">Helpdesk Guidelines </Link></td>
                </tr>
                <tr>
                  <td><Link to="/guidelines/inventory-management-system/introduction">IMS Guidelines </Link></td>
                </tr>
                <tr>
                  <td><Link to="/guidelines/approval-system/introduction">AS Guidelines </Link></td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div className="row">
          <div className="col col-12 col-sm-12 col-md-0 col-lg-3 col-xl-3 col-xxl-3">

          </div>

          <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
            <table className="table project-feature-ul-anim ">
              <tbody>
                <tr>
                  <th>
                    <h4>Other Links</h4>
                  </th>
                </tr>
                <tr>
                  <td><Link to="/privacy-policy">Privacy Policy </Link></td>
                </tr>
                <tr>
                  <td><Link to="/legal-terms-of-use">Legal terms of Use </Link></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <Footer />
    </div >
  )
}

export default SiteMap