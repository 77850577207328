import React, { useState } from 'react'
import "./ScrollToTop.css"

function ScrollToTop() {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div>
            <button onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none' }} id="myBtn" className='scrollButton' title="Go to top"><i className="fa-solid fa-chevron-up"></i></button>
        </div>
    )
}

export default ScrollToTop