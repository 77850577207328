import React, { useEffect, useState } from 'react'
import CompanyCarousel from '../../Slider/CompanyCarousel';
import { CASPEfeatureData } from '../data'
import FeatureCardRows from '../../CommonComponents/ContentCards/FeatureCardRows'
import ClientsAcrossTheGlobe from '../../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag'
import Magamenu from '../../Navbar/Megamenu'
import './CareerAtSharepointEmpower.css';
import Footer from '../../Footer/Footer';
import JobOpenings from '../jobOpenings/JobOpenings';

const CareerAtSharepointEmpower = () => {

    const [caspefeaturedatalist, setCASPEfeatureData] = useState([])
    // const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    useEffect(() => {
        document.title = "Career at SharepointEmpower";
        window.scrollTo(0, 0)
        setCASPEfeatureData(CASPEfeatureData)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/career-at-sharepointempower"
                metaKeywords="career at sharepointempower, career at work"
            />
            <Magamenu />
            <JobOpenings />
            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row  mt-4">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                                <div className="light-mode-title">
                                    <h4 className="h4-sontentsubtitle-lm text-align-center h4-title-anim">
                                        Perks & benefits at SharepointEmpower
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="row service-icon-container-lm product-feature-row-1-anim">
                            {
                                caspefeaturedatalist?.map(ele => {
                                    return (
                                        <FeatureCardRows iconName={ele.iconName} title={ele.title} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Perk and benefits at Sharepoint Empower section End --> */}

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="before-row-margin-lm"></div>
                        <div className="row">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                                <div className="light-mode-title">
                                    <h5 className="h4-sontentsubtitle-lm text-align-center h5-title-anim">
                                        Company Culture and employee Engagement
                                    </h5>
                                </div>

                                <div className="light-mode-content">
                                    <p className="p-center-content-lm second-p-anim">
                                        We frequently encourage conducting training sessions, employee development meetings, and knowledge sharing within the company. The company's infrastructure provides modern equipment and the latest software to work with, which assist the team members work more efficiently. The employees are given enough space so that they can express themselves; put their innovative ideas into auction, thus creating a path to personal as well as company growth.
                                    </p>
                                    <p className="p-center-content-lm second-p-anim">
                                        We believe that engaging employees is of utmost importance for any organization, not to increase the job satisfaction but to build a good connection with the organization. Also, it is crucial that each and every employee works happily in an organization. One of the fundamentals of at SharepointEmpower Pvt. Ltd. is to make their employees' experience worth during their association. We frequently execute activities to add a little joy in busy schedule of our employees.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                            <div>
                                <CompanyCarousel></CompanyCarousel>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <ClientsAcrossTheGlobe />

            <Footer />
        </div>
    )
}

export default CareerAtSharepointEmpower