import React, { useEffect, useState, useContext, useRef } from 'react';
import { ApiDataContext } from '../Userguide/ApiDataContext';
import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { format } from 'date-fns';
import { Checkbox } from "primereact/checkbox";
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';

const PaymentPage = () => {
    const { apiProductPagedata, apiInvoicedata } = useContext(ApiDataContext);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [invoiceNumbers, setInvoiceNumbers] = useState([]);
    const form = useRef();
    const titlesAndPlans = apiProductPagedata?.map(({ projectTitle, planDescription, plan, certifiedImage }) => {
        const price = parseInt(plan.match(/\d+/)[0], 10);
        return { projectTitle, planDescription, price, certifiedImage };
    });

    useEffect(() => {
        if (apiInvoicedata && Array.isArray(apiInvoicedata)) {
            const billNumbers = apiInvoicedata
                .filter(item => item.selectType === "Invoice")
                .map(item => item.randomBillNO)
                .sort((a, b) => a - b);
            setInvoiceNumbers(billNumbers);
        }
    }, [apiInvoicedata]);

    useEffect(() => {
        document.title = "Order Page";
        window.scrollTo(0, 0);
    }, []);

    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userCompany: '',
        userCompanyAdderss: '',
        userMobileno: 0,
        userService: [],
        CustomizationDetail: [],
        GrandTotal: 0
    });

    const now = new Date();

    const handleClose = () => setVisibleDialog(false);
    const closeErrorDialog = () => setErrorDialog(false);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const compareObjects = async (_saveRawdata, result) => {
        const resultData = {
            OrderId: result.order_id,
            Amount: result.sdk_payload.payload.amount,
            CustomerEmail: result.sdk_payload.payload.customerEmail,
            CustomerPhone: result.sdk_payload.payload.customerPhone,
            payment_page_client_id: result.sdk_payload.payload.clientId,
            action: result.sdk_payload.payload.action,
            Currency: result.sdk_payload.payload.currency,
            FirstName: result.sdk_payload.payload.firstName,
            LastName: result.sdk_payload.payload.lastName,
            ReturnUrl: result.sdk_payload.payload.returnUrl,
        };

        return JSON.stringify(_saveRawdata) == JSON.stringify(resultData);
    };

    const secretKey = "sh@repoint1234567890sh@repoint#$";
    const iv = CryptoJS.enc.Utf8.parse("sh@repoint1234#$");

    // Function to encrypt data
    function encryptData(data) {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted.toString();

    }

    const validateForm = async () => {
        setSubmitted(true);

        const isFormInvalid = [
            !formData.userName.trim(),
            !isValidEmail(formData.userEmail.trim()),
            !formData.userCompany.trim(),
            !formData.userCompanyAdderss.trim(),
            !formData.userMobileno,
            formData.userService.length === 0,
        ].some(Boolean);

        if (isFormInvalid) return;

        const myHeaders = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*'
        });

        const getDateTime = new Date();
        const formattedDate = format(getDateTime, 'ddMMyyHHmmssSSS');

        const customergetDateTime = new Date();
        const customerformattedDate = format(customergetDateTime, 'ddMMyyHHmm');

        const rawData = {
            "OrderId": `orderId_${formattedDate}`,
            "Amount": formData.GrandTotal.replace('$', ''),
            "CustomerId": `${customerformattedDate}`,
            "CustomerEmail": formData.userEmail,
            "CustomerPhone": formData.userMobileno,
            // "payment_page_client_id": "hdfcmaster",
            "payment_page_client_id": "37692",
            "action": "paymentPage",
            "Currency": "USD",
            "FirstName": formData.userName,
            "LastName": formData.userCompany,
            // "ReturnUrl": "http://localhost:5220/api/sharepoint/qaPostOrderStatus"
            // "ReturnUrl": "https://apiqa.sharepointempower.com/api/sharepoint/postOrderStatus"
            "ReturnUrl": "https://api.sharepointempower.com/api/sharepoint/postOrderStatus"
        };

        localStorage.setItem('orderId', JSON.stringify({
            orderId: rawData.OrderId
        }))

        try {
            const encryptedData = encryptData(rawData);

            // const response = await fetch("http://localhost:5220/api/sharepoint/qaPostpaymentgetway", {
            // const response = await fetch("https://apiqa.sharepointempower.com/api/sharepoint/postpaymentgetway", {
            const response = await fetch("https://api.sharepointempower.com/api/sharepoint/postpaymentgetway", {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({ data: encryptedData })
            });

            const responseData = await response.json();
            const result = JSON.parse(responseData);

            let saveRawdata = { ...rawData };
            delete saveRawdata.CustomerId

            const compareObject = await compareObjects(saveRawdata, result)

            if (result?.payment_links?.web && compareObject) {
                window.open(result.payment_links.web, '_self');
            } else {
                setErrorDialog(true);
            }

        } catch (error) {
            console.error('Encryption or Fetch Error:', error);
        }
        setTimeout(() => {
            form.current.reset();
            setFormData({
                userName: '',
                userEmail: '',
                userCompany: '',
                userCompanyAdderss: '',
                userMobileno: '',
                userService: [],
                CustomizationDetail: [],
                GrandTotal: 0
            });
            setSubmitted(false);
        }, 300);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (e) => {
        const selectedServices = e.value;

        const updatedCustomizationDetail = selectedServices?.map(service => ({
            Customization: `M365 - ${service.projectTitle} * ${service.planDescription}`,
            Charges: service.price * 12,
        }));

        const total = updatedCustomizationDetail?.reduce((total, item) => total + (item.Charges || 0), 0) || 0;
        const transactionCharge = total * 0.04;
        updatedCustomizationDetail.push({
            Customization: "Transaction Charges + Bank Fees + Credit Card Fees (4%)",
            Charges: parseFloat(transactionCharge.toFixed(2)),
        });


        function formatNumber(num) {
            let str = num.toFixed(2);
            return str.endsWith(".00") ? str.slice(0, -1) : str;
        }
        const finalTotal = parseFloat(total + transactionCharge);

        setFormData(prevFormData => ({
            ...prevFormData,
            userService: selectedServices,
            CustomizationDetail: updatedCustomizationDetail,
            transactionCharge: transactionCharge,
            GrandTotal: `$${formatNumber(finalTotal)}`,
        }));

        localStorage.setItem('orderData', JSON.stringify({
            userService: selectedServices,
            CustomizationDetail: updatedCustomizationDetail,
            Companylocation: formData.userCompanyAdderss,
            totalPriceValue: finalTotal,
            GrandTotal: formatNumber(finalTotal)
        }));
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.checked);
    };

    return (
        <>
            <form ref={form} name="ContactForm" autoComplete='off'
                className="contact-us-form form-data-anim pl-6">
                <div className='flex row'>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="fname">Name <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} id="fname" name="userName" placeholder='Enter Your Name' onChange={handleChange} required />
                        {submitted && !formData?.userName && <small className="p-error"> Please enter your name</small>}
                    </div>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="email">Company Email Address <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} id="email" name="userEmail" placeholder='Enter Company Email Address' onChange={handleChange} required />
                        {submitted && !formData?.userEmail && <small className="p-error"> Please enter your valid email</small>}
                        {submitted && formData?.userEmail && !isValidEmail(formData.userEmail) && <span className="p-error">Invalid email address.</span>}
                    </div>
                </div>
                <div className='flex row'>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="company">Your Company <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} id="company" name="userCompany" placeholder='Enter Your Organization Name' onChange={handleChange} required />
                        {submitted && !formData?.userCompany && <small className="p-error"> Please enter your company name</small>}
                    </div>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className='font-semibold' htmlFor="mobileno">Mobile No <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} maxLength={15} placeholder='Enter Your Mobile Number' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} id="mobileno" name="userMobileno" onChange={handleChange} required />
                        {submitted && !formData?.userMobileno && <small className="p-error"> Please enter your Mobile Number</small>}
                    </div>
                </div>
                <div className='flex row'>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                        <label className='font-semibold' htmlFor="companyAdderss">Your Company Location <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" style={{ fontSize: '0.855rem', fontWeight: 500 }} id="companyAdderss" name="userCompanyAdderss" placeholder='This address will print on your invoice' onChange={handleChange} required />
                        {submitted && !formData?.userCompanyAdderss && <small className="p-error"> Please enter your company address</small>}
                    </div>
                </div>

                <label className='font-semibold' htmlFor="service">What kind of products are you looking for? <span style={{ color: 'red' }}>*</span></label>{submitted && !formData?.userService.length > 0 && <small className="p-error"> Please select product</small>}
                <br></br>
                <MultiSelect
                    value={formData.userService}
                    options={titlesAndPlans}
                    onChange={handleMultiSelectChange}
                    optionLabel="projectTitle"
                    display="chip"
                    style={{ fontSize: '0.855rem', fontWeight: 500 }}
                    className='paymentpageproductselect'
                    placeholder="Select Products"
                    filter
                    itemTemplate={(option) => (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span>{option.projectTitle}</span>
                                <img src={option.certifiedImage || ''} alt={option.projectTitle || ''} style={{ width: 130, height: 22, marginLeft: 8 }} />
                            </div>
                        </>
                    )}
                />
                <div className="selected-services">
                    {formData.userService?.length > 0 ? (
                        <table className="mt-3 w-full border-1 border-round-md">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 border-bottom-1 border-right-1">List of Purchasing Products</th>
                                    <th className="px-4 py-2 border-bottom-1">Price/Yearly</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.userService?.map((service, index) => (
                                    <tr key={index} className="border-bottom-1">
                                        <td className="px-4 py-2 border-right-1">
                                            {service.projectTitle} {service.planDescription}
                                        </td>
                                        <td className="px-4 py-2">${service.price * 12}</td>
                                    </tr>
                                ))}

                                <tr>
                                    <td className="px-4 py-2 border-bottom-1 border-right-1">Transaction Charges + Bank Fees + Credit Card Fees (4%)</td>
                                    <td className="px-4 py-2 border-bottom-1">${formData.transactionCharge?.toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td className="px-4 py-2 font-bold border-right-1">Grand Total
                                        <br /><span className='text-xs font-normal'>*All product prices are inclusive of GST, ensuring transparent and hassle-free purchase.</span>
                                    </td>
                                    <td className="px-4 py-2 font-bold align-items-center">{formData.GrandTotal}</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <p style={{ fontSize: '0.755rem', fontWeight: 500 }}>No services selected</p>
                    )}
                </div>
                <div className='mt-3 mb-3'>
                    <Checkbox onChange={handleCheckboxChange} checked={isChecked} /><span className='text-xs pl-2'> I agree with the <Link to='/legal-terms-of-use' className='text-blue-500'>Refund policy</Link> and the <Link to='/privacy-policy' className='text-blue-500'>Privacy policy.</Link></span>
                </div>
                <div className='flex justify-content-between align-items-center contact-btn-flex flex-wrap'>
                    <button type='button' className={`btn-gradient ${!isChecked ? 'no-hover' : ''}`} id="btn-submit" onClick={validateForm} disabled={!isChecked}>
                        Proceed to Payment
                    </button>
                </div>
            </form>
            <Modal show={visibleDialog} centered onHide={handleClose} className='success-modal-body'>
                <img src={require("../../../assets/images/icons/accept-icon.png")} className='sent-mail-alert-image' alt='Success' />
            </Modal>
            <Dialog
                visible={errorDialog}
                style={{ width: '350px' }}
                header="Error"
                modal
                onHide={closeErrorDialog}
                footer={<button onClick={closeErrorDialog} className="p-button p-component">OK</button>}
            >
                <p>Something went wrong, please try again.</p>
            </Dialog>
        </>
    )
}

export default PaymentPage;