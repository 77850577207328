export const COUNTRIES_DATA = [
    {
        "code": "CA",
        "latitude": 56.1304,
        "longitude": -106.3468,
        "name": "Canada",
        "productName": "Learning Management Portal",
        "companies": [
            {
                "name": "NetFlix",
                "logo": "3.png"
            },
            {
                "name": "Company 555",
                "logo": "2.png"
            },
            {
                "name": "NetFlix",
                "logo": "3.png"
            },
            {
                "name": "Company 555",
                "logo": "2.png"
            }
        ]
    },
    {
        "code": "US",
        "latitude": 37.0902,
        "longitude": -95.7129,
        "name": "U.S.A",
        "productName": "Asset Management System",
        "companies": [
            {
                "name": "Company C",
                "logo": "3.png"
            },
            {
                "name": "Company D",
                "logo": "2.png"
            }
        ]
    },
    {
        "code": "CL",
        "latitude": -35.6751,
        "longitude": -71.543,
        "name": "Chile",
        "productName": "Asset Management System",
        "companies": [
            {
                "name": "Company E",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "BE",
        "latitude": 50.5039,
        "longitude": 4.4699,
        "name": "Belgium",
        "productName": "Learning Management Portal",
        "companies": [
            {
                "name": "Company F",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "UK",
        "latitude": 51.5074,
        "longitude": -0.1278,
        "name": "London, U.K",
        "productName": "Audit Inspection",
        "companies": [
            {
                "name": "Company G",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "FR",
        "latitude": 48.8566,
        "longitude": 2.3522,
        "name": "Paris",
        "productName": "Asset Management System",
        "companies": [
            {
                "name": "Company H",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "ZA",
        "latitude": -30.5595,
        "longitude": 22.9375,
        "name": "South Africa",
        "productName": "Construction Management System",
        "companies": [
            {
                "name": "Company I",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "SZ",
        "latitude": -26.5225,
        "longitude": 31.4659,
        "name": "Swaziland",
        "productName": "Construction Management System",
        "companies": [
            {
                "name": "Company J",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "NL",
        "latitude": 52.1326,
        "longitude": 5.2913,
        "name": "Netherlands",
        "productName": "Construction Management System",
        "companies": [
            {
                "name": "Company K",
                "logo": "3.png"
            }
        ]
    },
    {   
        "code": "CH",
        "latitude": 46.8182,
        "longitude": 8.2275,
        "name": "Switzerland",
        "productName": "Approval System",
        "companies": [
            {
                "name": "Company L",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "IT",
        "latitude": 41.8719,
        "longitude": 12.5674,
        "name": "Italy",
        "productName": "Approval System",
        "companies": [
            {
                "name": "Company M",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "GR",
        "latitude": 39.0742,
        "longitude": 21.8243,
        "name": "Greece",
        "productName": "Leave Management System",
        "companies": [
            {
                "name": "Company N",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "SA",
        "latitude": 23.8859,
        "longitude": 45.0792,
        "name": "Saudi Arabia",
        "productName": "HelpDesk System",
        "companies": [
            {
                "name": "Company O",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "AE",
        "latitude": 23.4241,
        "longitude": 53.8478,
        "name": "UAE",
        "productName": "Leave Management System",
        "companies": [
            {
                "name": "Company P",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "SG",
        "latitude": 1.3521,
        "longitude": 103.8198,
        "name": "Singapore",
        "productName": "HelpDesk System",
        "companies": [
            {
                "name": "Company Q",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "AU",
        "latitude": -25.2744,
        "longitude": 133.7751,
        "name": "Australia",
        "productName": "Audit Inspection",
        "companies": [
            {
                "name": "Company R",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "JP",
        "latitude": 36.2048,
        "longitude": 138.2529,
        "name": "Japan",
        "productName": "Asset Management System",
        "companies": [
            {
                "name": "Company S",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "PL",
        "latitude": 51.9194,
        "longitude": 19.1451,
        "name": "Poland",
        "productName": "Learning Management Portal",
        "companies": [
            {
                "name": "Company T",
                "logo": "3.png"
            }
        ]
    },
    {
        "code": "DE",
        "latitude": 51.1657,
        "longitude": 10.4515,
        "name": "Germany",
        "productName": "Audit Inspection",
        "companies": [
            {
                "name": "Company U",
                "logo": "3.png"
            }
        ]
    }
];