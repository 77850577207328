
import React from 'react'

export default function MaterialSymbolsDelete({ color }) {
    return (
        <svg width="20" height="20" viewBox="0 0 50 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
            <path stroke='gray' d="M14.5835 43.75C13.4377 43.75 12.4571 43.3424 11.6418 42.5271C10.8266 41.7118 10.4182 40.7306 10.4168 39.5833V12.5H8.3335V8.33333H18.7502V6.25H31.2502V8.33333H41.6668V12.5H39.5835V39.5833C39.5835 40.7292 39.1759 41.7104 38.3606 42.5271C37.5453 43.3437 36.5641 43.7514 35.4168 43.75H14.5835ZM18.7502 35.4167H22.9168V16.6667H18.7502V35.4167ZM27.0835 35.4167H31.2502V16.6667H27.0835V35.4167Z" fill="gray" />
        </svg>
    )
}
