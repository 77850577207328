import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Magamenu from '../../Navbar/Megamenu';
import './OrderStatus.css';
import CryptoJS from 'crypto-js';
import { ApiDataContext } from '../Userguide/ApiDataContext';

export default function OrderStatus() {
    const { apiInvoicedata } = useContext(ApiDataContext);
    const { order_id } = useParams();
    const [orderStatus, setOrderStatus] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [localCustomizationDetail, setLocalCustomizationDetail] = useState([]);
    const [localPrice, setLocalPrice] = useState(0);
    const [localCompanyLocation, setLocalCompanyLocation] = useState("");
    const [countdown, setCountdown] = useState(10);
    const [newInvoiceNumber, setNewInvoiceNumber] = useState(null);

    useEffect(() => {
        if (apiInvoicedata && Array.isArray(apiInvoicedata)) {
            const billNumbers = apiInvoicedata
                .filter(item => item.selectType === "Invoice")
                .map(item => item.randomBillNO)
                .sort((a, b) => a - b);

            if (billNumbers.length > 0) {
                const lastInvoiceNumber = Math.max(...billNumbers);
                setNewInvoiceNumber(lastInvoiceNumber + 1);
            }
        }
    }, [apiInvoicedata]);


    useEffect(() => {
        if (order_id === "false" || (orderStatus && orderStatus.status !== 'CHARGED')) {
            const interval = setInterval(() => {
                setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
            }, 1000);

            const timeout = setTimeout(() => {
                navigate('/')
            }, 10000);

            return () => {
                clearInterval(interval);
                clearTimeout(timeout);
            };
        }
    }, [orderStatus]);

    useEffect(() => {
        const storedOrderData = JSON.parse(localStorage.getItem('orderData'));

        if (storedOrderData) {
            setLocalCustomizationDetail(storedOrderData.CustomizationDetail || []);
            setLocalPrice(storedOrderData.userService || 0);
            setLocalCompanyLocation(storedOrderData.Companylocation || '');
        }
    }, []);

    useEffect(() => {
        const fetchOrderStatus = async () => {
            try {

                const secretKey = "sh@repoint1234567890sh@repoint#$";
                const ivString = "sh@repoint1234#$";

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                    body: JSON.stringify({ order_id })
                };

                // const response = await fetch('http://localhost:5220/api/SharePoint/qaPostOrderStatusData', requestOptions);
                const response = await fetch('https://api.sharepointempower.com/api/SharePoint/postOrderStatusData', requestOptions);

                if (!response.ok) {
                    const errorData = await response.text();
                    throw new Error(`Failed to fetch order status. Status: ${response.status}. Error: ${errorData}`);
                }

                const encryptedData = await response.json();

                // Decrypt the data
                const decryptedBytes = await CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(secretKey), {
                    iv: CryptoJS.enc.Utf8.parse(ivString),
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });

                const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
                const responseData = JSON.parse(decryptedData);

                setOrderStatus(responseData);
            } catch (err) {
                console.error("Error fetching order status:", err);
                setError(err.message);
            }
        };

        if (order_id !== "false") {
            fetchOrderStatus();
        }
    }, [order_id]);

    const handleQuotationSubmission = async () => {
        const myHeaders = new Headers({
            "Content-Type": "application/json;odata=verbose",
            "Authorization": "Bearer 4352345342"
        });

        const apiData = {
            "Title": orderStatus.metadata.payment_page_sdk_payload.firstName,
            "EmailAddress": orderStatus.metadata.payment_page_sdk_payload.customerEmail,
            "Companyname": orderStatus.metadata.payment_page_sdk_payload.lastName,
            "Companylocation": localCompanyLocation,
            "Total": orderStatus.amount,
            "SelectType": "Invoice",
            "Date": orderStatus.date_created,
            "PaymentDate": orderStatus.date_created,
            "Discount": "0",
            "Note": "",
            "BillDuration": "Yearly",
            "BalanceOwned": orderStatus.amount,
            "MoveToClientTracker": "false",
            "CurrencyId": "1",
            "CustomizationDetail": JSON.stringify(localCustomizationDetail),
            "Price": JSON.stringify(localPrice),
            "RandomBillNO": `${newInvoiceNumber}`,
            "PaymentMode": orderStatus.status === 'CHARGED' ? "true" : "false",
            "PurchaseOrder": orderStatus.order_id,
            "Comments": ` OrderId : ${orderStatus.order_id}, Total: ${orderStatus.amount} USD, Date: ${orderStatus.date_created} `,
            "BankDetails": "HDFC smart gateway"
        };

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(apiData),
        };

        try {
            // const response = await fetch("http://localhost:5220/api/sharepoint/qaPostinvoicedata", requestOptions);
            // const response = await fetch("https://apiqa.sharepointempower.com/api/sharepoint/postinvoicedata", requestOptions);
            const response = await fetch("https://api.sharepointempower.com/api/sharepoint/postinvoicedata", requestOptions);
            if (response.ok) {
                localStorage.removeItem('orderData');
                localStorage.removeItem('orderId');
            } else {
                console.error(`Failed to post quotation data. Status: ${response.status}`);
            }
        } catch (error) {
            console.log('Error posting quotation data:', error);
        }
    };

    useEffect(() => {
        const submitQuotationIfCharged = async () => {
            const orderData = localStorage.getItem('orderData');
            const storedOrderId = JSON.parse(localStorage.getItem('orderId'));

            if (
                orderStatus &&
                orderData &&
                (order_id === storedOrderId.orderId) &&
                newInvoiceNumber !== null
            ) {
                await handleQuotationSubmission();
            }
        };

        submitQuotationIfCharged();
    }, [orderStatus, newInvoiceNumber]);


    return (
        <>
            <Magamenu />
            <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
                {/* <img className='backimgproducts' src={require("../../../assets/images/about_us_card_0.1.png")} alt="" /> */}
                <video id='myVideo' height="100%" width="100%" autoPlay loop muted playsInline preload="auto" className='backimgproducts'>
                    <source src={require('../../../assets/videos/LandingPageVideo/sharepointEmpower-2.mp4')} type='video/mp4' />
                </video>
            </div>
            <div className='payment-container'>
                <div className="payment-status-container mt-8">
                    {(order_id === "false" || orderStatus) && <Link to="/" className='close-button'>
                        <i className="fa fa-xmark" />
                    </Link>}
                    {
                        order_id === "false" ? (
                            <div className="auth-failed">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                <h2 className='status-title'>Something Went Wrong!</h2>
                                <p>An error occurred. We are working to resolve the issue.</p>
                            </div>
                        ) : (
                            orderStatus ? (
                                (() => {
                                    switch (orderStatus.status) {
                                        case 'CHARGED':
                                            return (
                                                <div className="payment-success">
                                                    <i className="fa-solid fa-circle-check"></i>
                                                    <h2 className='status-title'>Payment Successful</h2>
                                                    <p>Thank you for purchasing a product from SharePointEmpower! For any questions, please contact us at <a className='text-blue-500' href="mailto:support@sharepointempower.com">support@sharepointempower.com</a></p>
                                                    <div className="payment-details">
                                                        <table className="details-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><strong>Merchant :</strong></td>
                                                                    <td>{orderStatus.metadata.merchant_payload.displayBusinessAs}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Amount :</strong></td>
                                                                    <td>USD {orderStatus.amount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Order ID :</strong></td>
                                                                    <td>{orderStatus.order_id}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Payment Mode :</strong></td>
                                                                    <td>{orderStatus.payment_method_type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Transaction ID :</strong></td>
                                                                    <td>{orderStatus.txn_id}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <div className='invoicebtn p-3'>
                                                        <Button label="Download Invoice" color='#00C885' />
                                                    </div> */}
                                                </div>
                                            );
                                        case 'PENDING':
                                            return (
                                                <div className="payment-pending">
                                                    <i className="fa-solid fa-clock"></i>
                                                    <h2 className='status-title'>Payment Pending</h2>
                                                    <p>The payment is awaiting completion. Kindly check your payment status for updates.</p>
                                                </div>
                                            );
                                        case 'PENDING_VBV':
                                            return (
                                                <div className="verification-required">
                                                    <i className="fa-solid fa-lock"></i>
                                                    <h2 className='status-title'>Verification Unsuccessful!</h2>
                                                    <p>Your verification attempt failed. Ensure all information is correct and attempt once more</p>
                                                </div>
                                            );
                                        case 'AUTHENTICATION_FAILED':
                                            return (
                                                <div className="authentication-failed">
                                                    <i className="fa-solid fa-fingerprint"></i>
                                                    <h2 className='status-title'>Authentication Failed</h2>
                                                    <p>Authentication failed. Please try again or use a different payment method.</p>
                                                </div>
                                            );
                                        case 'AUTHORIZATION_FAILED':
                                            return (
                                                <div className="authorization-failed">
                                                    <i className="fa-solid fa-fingerprint"></i>
                                                    <h2 className='status-title'>Authorization Failed</h2>
                                                    <p>We couldn't verify your identity. Please ensure your details are correct and try again</p>
                                                </div>
                                            );
                                        default:
                                            return (
                                                <div className="unknown-status">
                                                    <h2 className='p-3'>Unknown Status</h2>
                                                    <p>We encountered an unexpected status: {orderStatus.status}</p>
                                                </div>
                                            );
                                    }
                                })()
                            ) : (
                                <p>Loading...</p>
                            )
                        )
                    }
                </div>
                {(order_id === "false" || (orderStatus && orderStatus.status !== 'CHARGED')) && (
                    <div className="redirect-timer">
                        <p>Redirecting to home page in <strong>{countdown}</strong> seconds...</p>
                    </div>
                )}
            </div>
        </>
    );
}