import React from 'react'

export default function AssetManagerGuide({ color }) {
    return (
        <svg width="20" height="20" viewBox="0 0 63 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_207_22)">
                <path stroke="gray" d="M21.875 25C25.1902 25 28.3696 23.683 30.7138 21.3388C33.058 18.9946 34.375 15.8152 34.375 12.5C34.375 9.18479 33.058 6.00537 30.7138 3.66117C28.3696 1.31696 25.1902 0 21.875 0C18.5598 0 15.3804 1.31696 13.0362 3.66117C10.692 6.00537 9.375 9.18479 9.375 12.5C9.375 15.8152 10.692 18.9946 13.0362 21.3388C15.3804 23.683 18.5598 25 21.875 25ZM17.4121 29.6875C7.79297 29.6875 0 37.4805 0 47.0996C0 48.7012 1.29883 50 2.90039 50H31.5234C31.2207 49.1406 31.1621 48.2031 31.3867 47.2852L32.8516 41.416C33.125 40.3125 33.6914 39.3164 34.4922 38.5156L38.4277 34.5801C35.293 31.5527 31.0352 29.6875 26.3281 29.6875H17.4023H17.4121ZM59.9414 23.0176C58.418 21.4941 55.9473 21.4941 54.4141 23.0176L51.543 25.8887L58.4766 32.8223L61.3477 29.9512C62.8711 28.4277 62.8711 25.957 61.3477 24.4238L59.9414 23.0176ZM36.709 40.7227C36.3086 41.123 36.0254 41.6211 35.8887 42.1777L34.4238 48.0469C34.2871 48.584 34.4434 49.1406 34.834 49.5313C35.2246 49.9219 35.7812 50.0781 36.3184 49.9414L42.1875 48.4766C42.7344 48.3398 43.2422 48.0566 43.6426 47.6562L56.2598 35.0293L49.3262 28.0957L36.709 40.7227Z" fill="gray" />
            </g>
            <defs>
                <clipPath id="clip0_207_22">
                    <rect width="62.5" height="50" fill="gray" />
                </clipPath>
            </defs>
        </svg>
    )
}

