import React, { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactHelmetTag from "../CommonComponents/ReactHelmet/ReactHelmetTag";
import ProductPage from "./ProductPage/ProductPage";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from "../Navbar/Megamenu";

const ConstructionManagementSystem = () => {
  
  useEffect(() => {
    document.title = "Construction Management System";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/construction-management-system"
        metaTitle="Sharepoint construction management system"
        metaDescription="Efficiently manage construction projects with our SharePoint Construction Management System. Streamline communication, document control, and project tracking for improved collaboration and productivity"
        metaKeywords="construction management software, construction project management software, project management software construction, software for construction project management, management software for construction, best construction management software, construction management system"
      />

      <Magamenu />
      <LazyLoadComponent>
        <ProductPage project="Construction Management System" />
      </LazyLoadComponent>
    </div>
  );
};

export default ConstructionManagementSystem;
