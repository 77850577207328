export const CRMAPI = (data) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json;odata=verbose");
    myHeaders.append("Authorization", "Bearer 4352345342");

    let raw = {
        "Title": data.name ? data.name : "",
        "Email": data.email ? data.email : "",
        "Company": data.company ? data.company : "",
        "ContactNumber": data.mobile ? data.mobile : "",
        "Country": data.location ? data.location : "",
        "OfferSource": data.service ? data.service : "",
        "LeadSource": 8,
        "Requirements": data.description ? data.description : ""
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw),
    };

    fetch("https://api.sharepointempower.com/api/DynamicsCRM", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}