import React, { useEffect, useState, useRef, useCallback } from "react";
import { COUNTRIES_DATA } from "./data/countries_data";
import HEX_DATA from "./data/countries_hex_data.json";
import Globe from "react-globe.gl";

export default function CustomGlobe() {
    const globeEl = useRef();
    const [hex, setHex] = useState({ features: [] });
    const [hoveredCountry, setHoveredCountry] = useState(null);

    useEffect(() => {
        setHex(HEX_DATA);
    }, []);

    useEffect(() => {
        if (globeEl.current) {
            globeEl.current.controls().autoRotate = true;
            globeEl.current.controls().autoRotateSpeed = 0.5;

            const MAP_CENTER = { lat: 0, lng: 0, altitude: 2 };
            globeEl.current.pointOfView(MAP_CENTER, 0);
        }
    }, []);

    const labelsData = COUNTRIES_DATA?.map((country) => ({
        lat: country.latitude,
        lng: country.longitude,
        label: country.name,
        code: country.code,
        productName: country.productName,
        name: country.name,
        flagUrl: `https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`,
        companies: country.companies
    }));

    const handlePointHover = (point) => {
        if (point) {
            globeEl.current.controls().autoRotate = false;
            setHoveredCountry(point);
        } else {
            globeEl.current.controls().autoRotate = true;
            setHoveredCountry(null);
        }
    };

    const handleMouseLeaveFlag = () => {
        handlePointHover(null);
    };

    return (
        <div style={{ position: "relative", height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="mt-6">
            <Globe
                ref={globeEl}
                backgroundImageUrl="https://raw.githubusercontent.com/IEEE-VIT/CTF-Frontend/master/src/assets/night-sky.png"
                backgroundColor="black"
                htmlElementsData={labelsData}
                htmlTransitionDuration={1000}
                htmlElement={(d) => {
                    const el = document.createElement('div');
                    el.innerHTML = `
                        <div style="display: flex; align-items: center;">
                            <img src="${d.flagUrl}" alt="${d.code}" class="p-1 flag flag-${d.code?.toLowerCase()}" style="width: 30px; height: 20px; margin-right: 5px;" />
                            <span style="font-size:10px">${d.name}</span>
                        </div>
                    `;
                    el.style.color = 'white';
                    el.style.pointerEvents = 'auto';
                    el.style.position = 'absolute';
                    el.style.transform = 'translate(-50%, -50%)';
                    el.style.cursor = 'pointer';

                    el.onmouseenter = () => handlePointHover(d);
                    el.onmouseleave = handleMouseLeaveFlag;

                    return el;
                }}
                hexPolygonsData={hex.features}
                hexPolygonResolution={3}
                hexPolygonMargin={0.62}
                hexPolygonColor={useCallback(() => "gray", [])}
                showAtmosphere={true}
                atmosphereColor={"gray"}
                atmosphereAltitude={0.15}
            />
            {hoveredCountry && (
                <div
                    style={{
                        position: "absolute",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: "0px",
                        background: "black",
                        borderRadius: "15px",
                        zIndex: 999,
                        border: "1px solid gray",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                    }}
                    onMouseLeave={handleMouseLeaveFlag}
                >
                    <div className="p-2" style={{ textAlign: 'center' }}>
                        {/* <div style={{ borderRadius: '50%', backgroundColor: 'black', width: '40px', height: '40px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', padding: '1px' }}>
                            <img className="w-9 h-9" src={hoveredCountry.flagUrl} alt="" />
                        </div>
                        <div>
                            <span style={{ fontSize: '16px' }}>M365</span>
                        </div>
                        <div className="font-semibold">{hoveredCountry.productName}</div>
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <p className="p-1">{hoveredCountry.label}</p>
                            <img alt={hoveredCountry.code} src={hoveredCountry.flagUrl} className={`p-1 flag flag-${hoveredCountry.code?.toLowerCase()}`} style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                        </div> */}
                        <div className="p-2" style={{ textAlign: 'center' }}>
                            <div style={{ marginBottom: '10px' }}>
                                <span style={{ fontSize: '16px' }}>{hoveredCountry.name}</span>
                            </div>
                            <div className="flex flex-row">
                                {hoveredCountry?.companies?.map((company, index) => (
                                    <div className="flex flex-row">
                                        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '5px', padding: '10px' }}>
                                            <img src={require(`../src/assets/images/Logos/${company.logo}`)} alt={company.name} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                            <span style={{ fontSize: '14px', color: 'white' }}>{company.name}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
