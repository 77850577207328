import React, { useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../ReactHelmet/ReactHelmetTag'
import ClientsAcrossTheGlobe from '../ClientsAcrossTheGlobe/ClientsAcrossTheGlobe'
import { Link } from 'react-router-dom'
import Magamenu from '../../Navbar/Megamenu';
import Footer from '../../Footer/Footer';
import PaymentPage from './PaymentPage';
import './PaymentGetway.css'

const PaymentGetway = () => {

    useEffect(() => {
        document.title = "Payment";
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='mt-7'>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/Order-page"
                metaKeywords="contact us form"
            />

            <Magamenu />
            <div className="fixed-top-container">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col col-9 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div id="first-section-margin-top-for-navbar" className="light-mode-title">
                                    <h1 className="h1-tag-lm first-h1-anim h1-heading">Proceed with Product Payment</h1>
                                </div>
                            </div>
                            <div className="col col-3 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex justify-content-end align-items-center">
                                <div id="first-section-margin-top-for-navbar" className="light-mode-title quotationbtn d-flex">
                                    <Link to="/faqs" className='pricingfaq'>
                                        <i className="fa fa-circle-question mr-1" />
                                        <span className='m-0'>FAQs</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section>
                <div className="container-fluid">
                    <div className="container mt-4">
                        <div className="before-row-margin-lm"></div>
                        <div className="form-container form-container-anim py-0">
                            <div className="row">
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-0">
                                    <PaymentPage />
                                </div>
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="form-img-container form-data-anim pt-4">
                                        <LazyLoadImage
                                            src={require("../../../assets/images/contact-us/form_image.png")}
                                            alt="contact-us-form"
                                            effect="blur"
                                            visibleByDefault={require("../../../assets/images/contact-us/form_image.png")}
                                        />
                                    </div>
                                    <div className='justify-content-center pt-3 payment-page-support'>
                                        <h5 className='flex justify-content-center font-bold'>Need assistance? Talk to an expert!</h5>
                                        <h6 className='flex justify-content-center align-items-center'>
                                            <i className="fa-regular fa-envelope mr-1"></i>
                                            <a href="mailto:support@sharepointempower.com">support@sharepointempower.com</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <ClientsAcrossTheGlobe />
            <Footer />
        </div >
    )
}

export default PaymentGetway