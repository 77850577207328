import React, { useState, useEffect } from 'react';
import './Translate.css';
import Translatedropdown from './Translatedropdown';

const TranslateWidget = () => {
  const localStorageKey = 'translateselectedlanguage';

  let [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem(localStorageKey);
    return storedLanguage ? JSON.parse(storedLanguage) : { name: 'English', code: 'GB', langcode: 'en' };
  });

  let [selectlang, setSelectlang] = useState(selectedLanguage.langcode);

  useEffect(() => {
    const initializeAndSetLocalStorage = async () => {
      await initializeLanguageTranslateElement();
      localStorage.setItem(localStorageKey, JSON.stringify(selectedLanguage));
    };

    initializeAndSetLocalStorage();
  }, [selectedLanguage]);


  const initializeLanguageTranslateElement = async () => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = await googleTranslateElementInit;
  }

  const googleTranslateElementInit = async () => {
    new window.google.translate.TranslateElement(
      {
        defaultLanguage: selectlang,
        includedLanguages: 'en,fr,de,ja,es,ar,ru,pt',
      },
      'google_translate_element'
    );

    await onChangeLanguageTranslation();
  }

  const onChangeLanguageTranslation = () => {
    setTimeout(async () => {
      const languageDropdown = await document.querySelector('.goog-te-combo');
      if (languageDropdown) {
        languageDropdown.value = selectlang
        languageDropdown.addEventListener('change', () => {
          const selectedLanguageValue = languageDropdown.value;
          window.google.translate.TranslateElement({ pageLanguage: selectedLanguageValue }, 'google_translate_element');
        });
        languageDropdown.dispatchEvent(new Event("change"));
      }
    }, 500);
  }

  const handleLanguageChange = async (e) => {
    setSelectedLanguage(e.value);

    switch (e.value.code) {
      case "GB":
        setSelectlang('en');
        break;
      case "FR":
        setSelectlang('fr');
        break;
      case "DE":
        setSelectlang('de');
        break;
      case "JP":
        setSelectlang('ja');
        break;
      case "SA":
        setSelectlang('ar');
        break;
      case "ES":
        setSelectlang('es');
        break;
      case "RU":
        setSelectlang('ru');
        break;
    }
    onChangeLanguageTranslation();
    window.location.reload();
  }

  const allLanguages = [
    { name: 'English', code: 'GB', langcode: 'en' },
    { name: 'Spanish', code: 'ES', langcode: 'es' },
    { name: 'French', code: 'FR', langcode: 'fr' },
    { name: 'Russian', code: 'RU', langcode: 'ru' },
    { name: 'Arabic', code: 'SA', langcode: 'ar' },
    { name: 'Japanese', code: 'JP', langcode: 'ja' },
    { name: 'German', code: 'DE', langcode: 'de' },
  ];

  const selectedlanguageTemplate = (option) => {
    if (option) {
      return (
        <div className="flex align-items-center multilangdropdown">
          <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '24px' }} />
          <div translate='no' className='nav-link' style={{ fontSize: '18px', fontFamily: 'Poppins' }}>{option.name ? option.name.slice(0, 2).toUpperCase() : ''}</div>
        </div>
      );
    }

    return <span>Select Language</span>;
  };

  const languageOptionTemplate = (option) => {
    return (
      <div className='drplanguagename'>
        <div className="flex align-items-center">
          <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
          <div translate='no' >{option.name}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="google_translate_element" className='d-none'></div>
      <div className="card flex justify-content-center langdropdown">
        <Translatedropdown
          value={selectedLanguage}
          options={allLanguages}
          onChange={handleLanguageChange}
          optionLabel="name"
          valueTemplate={selectedlanguageTemplate}
          itemTemplate={languageOptionTemplate}
        />
      </div>
    </>
  );
};

export default TranslateWidget;
