import React, { useEffect } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import ProductPage from './ProductPage/ProductPage'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from '../Navbar/Megamenu';

const SPEIntranet = () => {

    useEffect(() => {
        document.title = "SPE Intranet";
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/spe-intranet"
                metaTitle="Sharepoint SPE Intranet"
                metaDescription="SPE Intranet management system promotes seamless collaboration with features like document libraries and system customization, enabling efficient teamwork regardless of location. It optimizes internal communication, ensuring security and offering analytics to enhance productivity and streamline workflows. Recognized with the Microsoft 365 app compliance badge, it reflects our commitment to secure, effective learning platforms, placing us among a select group of companies."
                metaKeywords="spe intranet, office 365 intranet solutions, office 365 intranets, microsoft intranet software, m365 intranet software, microsoft office intranet, microsoft office 365 intranet, microsoft office 365 sharepoint intranet, microsoft teams intranet app, microsoft 365 intranet system, microsoft team intranet app, sharepoint intranet software, intranet app, microsoft intranet solutions, company intranet app, employee intranet app, sharepoint intranet templates free download, intranet applications, intranet mobile app, microsoft intranet platform, best intranet apps, intranet app download,"
            />
            <Magamenu />
            <LazyLoadComponent>
                <ProductPage project="SPE Intranet" />
            </LazyLoadComponent>
        </div>
    )
}

export default SPEIntranet