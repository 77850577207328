import React, { useEffect, useState } from 'react';
import './ClientsAcrossTheGlobe.css';
import useLocalStorage from 'use-local-storage';

const ClientsAcrossTheGlobe = () => {
    const [theme, setTheme] = useLocalStorage('theme', 'light');

    return (
        <div className='relative'>
            <div className="container-fluid clientmap">
                <div className="container">
                    <div className="before-row-margin-lm"></div>
                    <div className="row justify-content-center">
                        <div>
                            <div id="first-section-margin-top-for-navbar">
                                <h1 className="h1-tag-lm first-h1-anim h1-heading">Clients Across The Globe</h1>
                            </div>
                            <div className="light-mode-content">
                                <p className="p-content-lm first-p-anim">
                                    At <b>SharepointEmpower</b>, we are proud to serve clients across the globe with our innovative and high-quality services. With our global reach, we have the ability to work with clients from different time zones, cultures, and backgrounds. Our team is experienced in collaborating remotely through video conferencing, email, and other communication tools to ensure seamless communication with our clients.
                                </p>
                            </div>
                        </div>

                        <div className="flex justify-content-center relative mapimage">
                            {/* <img src={theme === 'light'
                                ? require('../../../assets/images/map-light-theme.png')
                                : require('../../../assets/images/map-dark-theme.png')} alt="Map" className={window.innerWidth < 800 ? 'w-full' : ''} /> */}
                            <img src={theme === 'light' ? 'https://www.sharepointempower.com/assets/Images/Global-Map/map-light-theme.png' : 'https://www.sharepointempower.com/assets/Images/Global-Map/map-dark-theme.png'} alt="loading" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientsAcrossTheGlobe;
