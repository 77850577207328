import React, { useState, useEffect, useRef } from 'react';
import './Translate.css';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';

const Translatedropdown = ({ value, options, onChange, optionLabel, valueTemplate, itemTemplate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    onChange({ value: option });
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="translate-custom-dropdown">
      <div className="custom-dropdown-value" onClick={handleToggleDropdown}>
        {valueTemplate(value)}
      </div>
      {isOpen && (
        <div className="custom-dropdown-options">
          {options.map((option) => (
            <div key={option.code} className="custom-dropdown-option" onClick={() => handleSelectOption(option)}>
              {itemTemplate(option)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Translatedropdown;
