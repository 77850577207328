import React, { useEffect } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import ProductPage from './ProductPage/ProductPage'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from '../Navbar/Megamenu';

const InventoryManagementSystem = () => {


    useEffect(() => {
        document.title = "Inventory Management System";
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/inventory-management-system"
                metaTitle="Sharepoint Inventory Management System"
                metaDescription="Boost your operational efficiency with our Inventory Management System. Effortlessly oversee orders, interactions, and inventory to ensure precision and productivity. Take charge with real-time tracking, in-depth insights, and effective product organization. Integrate barcoding, specify default locations, and monitor payments for streamlined operations and unparalleled customer satisfaction."
                metaKeywords="inventory management system, inventory software, inventory management software, sharepoint inventory management software, microsoft inventory system, microsoft teams inventory system app, micro365 inventory management system, warehouse management system, warehouse inventory management software, inventory system, inventory tracking system, best inventory management software, stock management software, warehouse management software, inventory management app, real-time inventory tracking system, IT asset inventory management software, barcoding software for inventory, inventory scanner and software, digital warehouse management system, warehouse fulfillment software, warehouse management solution, inventory application software, order inventory management software, inventory management platform, warehouse inventory management systems, inventory management software list, warehouse accounting software, inventory management scanner and software, hardware inventory management software, inventory tracking programs, stock inventory, store inventory system, asset tracking solutions, production inventory management software, IT inventory management open source, qr inventory system, multi-location inventory management software, inventory and asset management software, manufacturing inventory software, warehouse management system software, best order management system, best inventory management, store inventory management software, warehouse inventory management software with barcode scanner, best stock management software, inventory software systems, IT inventory system, inventory planning, simple inventory system, business inventory system"
            />
            <Magamenu />
            <LazyLoadComponent>
                <ProductPage project="Inventory Management System" />
            </LazyLoadComponent>
        </div>
    )
}

export default InventoryManagementSystem