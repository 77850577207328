import React, { useState, useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactHelmetTag from "../CommonComponents/ReactHelmet/ReactHelmetTag";
import ProductPage from "./ProductPage/ProductPage";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from "../Navbar/Megamenu";

const AssetManagementSystem = () => { 

  useEffect(() => {
    document.title = "Asset Management System";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/asset-management-system"
        metaTitle="Sharepoint Digital Asset Management System"
        metaDescription="Empower your organization with our comprehensive SharePoint digital asset management system. Streamline asset organization, collaboration, and retrieval for improved productivity and efficiency."
        metaKeywords="asset management system, asset management software, digital asset management system, Sharepoint asset management system, sharepoint digital asset management, digital asset management sharepoint, sharepoint asset management system, sharepoint digital asset management system"
      />

      <Magamenu />
      <LazyLoadComponent>
        <ProductPage project="Asset Management System" />
      </LazyLoadComponent>
    </div>
  );
};

export default AssetManagementSystem;
