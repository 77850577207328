import React, { useContext, useEffect, useState } from 'react';
import './ProductOverview.css';
import { ApiDataContext } from '../../CommonComponents/Userguide/ApiDataContext';
import useLocalStorage from 'use-local-storage';
import { Skeleton } from 'primereact/skeleton';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';

const ProductOverview = () => {

    const { apiProductPagedata } = useContext(ApiDataContext);
    const [theme, setTheme] = useLocalStorage('theme', 'light');
    const [loading, setLoading] = useState(true);
    const [hoveredItem, setHoveredItem] = useState(null);

    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    useEffect(() => {
        if (apiProductPagedata && apiProductPagedata.length > 0) {
            setLoading(false);
            setHoveredItem({
                projectTitle: apiProductPagedata[0]?.projectTitle,
                description: apiProductPagedata[0]?.productKnowDescription,
                buttonLink: apiProductPagedata[0]?.download,
                backgroundImage: apiProductPagedata[0]?.productBackImage,
                helpCenter: apiProductPagedata[0]?.helpCenter
            })
        }
    }, [apiProductPagedata]);

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    };

    const handleMouseEnter = (item) => {
        setHoveredItem({
            projectTitle: item.projectTitle,
            description: item.productKnowDescription,
            buttonLink: item.download,
            backgroundImage: item.productBackImage,
            helpCenter: item.helpCenter
        });
    };

    function getLogoForProduct(productName) {

        switch (productName) {
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../../assets/images/icons/LMP-w.png') : require('../../../assets/images/icons/LMP-b.png');
            case 'Asset Management System':
                return theme === 'light' ? require('../../../assets/images/icons/AMS-w.png') : require('../../../assets/images/icons/AMS-b.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../../assets/images/icons/audit-w.png') : require('../../../assets/images/icons/audit-b.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../../assets/images/icons/CMS-w.png') : require('../../../assets/images/icons/CMS-b.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../../assets/images/icons/spe-Intranet-w.png') : require('../../../assets/images/icons/spe-Intranet-b.png');
            case 'Helpdesk System':
                return theme === 'light' ? require('../../../assets/images/icons/HD-w.png') : require('../../../assets/images/icons/HD-b.png');
            case 'Leave Management System':
                return theme === 'light' ? require('../../../assets/images/icons/leave-ms-w.png') : require('../../../assets/images/icons/leave-ms-b.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../../assets/images/icons/IMS-w.png') : require('../../../assets/images/icons/IMS-b.png');
            case 'Approval System':
                return theme === 'light' ? require('../../../assets/images/icons/AS-w.png') : require('../../../assets/images/icons/AS-b.png');

            default:
                return null;
        }
    }

    function getcardLogoForProduct(productName) {

        switch (productName) {
            case 'Learning Management Portal':
                return require('../../../assets/images/icons/LMP-w.png');
            case 'Asset Management System':
                return require('../../../assets/images/icons/AMS-w.png');
            case 'Audit Inspection':
                return require('../../../assets/images/icons/audit-w.png');
            case 'Construction Management System':
                return require('../../../assets/images/icons/CMS-w.png');
            case 'SPE Intranet':
                return require('../../../assets/images/icons/spe-Intranet-w.png');
            case 'Helpdesk System':
                return require('../../../assets/images/icons/HD-w.png');
            case 'Leave Management System':
                return require('../../../assets/images/icons/leave-ms-w.png');
            case 'Inventory Management System':
                return require('../../../assets/images/icons/IMS-w.png');
            case 'Approval System':
                return require('../../../assets/images/icons/AS-w.png');

            default:
                return null;
        }
    }

    return (
        <>
            <div className="container p-4 my-3 productpageback">
                <div className='row'>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-5 col-xl-3 col-xxl-3'>
                        <div className="sidebar">
                            <ul className="system-list">
                                {loading ? (
                                    <>
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                    </>

                                ) : (

                                    apiProductPagedata?.map((item) => {
                                        let updatedTitle = item?.projectTitle

                                        if (item.projectTitle?.includes('Management') && (item.projectTitle?.includes('System') || item.projectTitle?.includes('Portal'))) {
                                            updatedTitle = item.projectTitle.replace('System', '').replace('Portal', '').trim();
                                        }

                                        return (
                                            <li className='zoom-on-hover' title={updatedTitle}>
                                                <div className='flex align-items-center' onMouseEnter={() => handleMouseEnter(item)}>
                                                    <Link to={item?.routPath} className='gap-2 '>
                                                        <img className="productIcon" src={getLogoForProduct(item.projectTitle)} alt={item.projectTitle} />
                                                        <span>{updatedTitle}</span>
                                                        <img className="microsoftbadge" src={theme === 'light' ? require('../../../../src/assets/images/Microsoft_Badge_white.png') : require('../../../../src/assets/images/Microsoft_Badge_black.png')} alt={item.projectTitle} />
                                                    </Link>
                                                </div>
                                                <Tooltip target=".zoom-on-hover" position="bottom" mouseTrack mouseTrackTop={-20} className='z-5' />
                                            </li>
                                        )
                                    })
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-7 col-xl-9 col-xxl-9 '>
                        <div className='h-full bg-cover flex align-items-end border-round-lg mobiledisable' style={{ backgroundImage: hoveredItem?.backgroundImage && `url(${require(`../../../assets/images/productsBackground/${hoveredItem?.backgroundImage}.jpg`)})` }}>
                            <div className="main-content m-3 w-full text-white" >
                                {loading ? (
                                    <>
                                        <div className="flex gap-2">
                                            <Skeleton width="5%" height="40px" className="my-2" />
                                            <Skeleton width="50%" height="40px" className="my-2" />
                                        </div>
                                        <Skeleton width="100%" height="100px" className="my-2" />
                                        <div className="flex gap-2">
                                            <Skeleton width="120px" height="40px" className="my-2" />
                                            <Skeleton width="120px" height="40px" className="my-2" />
                                        </div>
                                    </>
                                ) : (
                                    hoveredItem && (
                                        <>
                                            <div className="title flex align-items-center gap-2">
                                                <img className="allproductIcon" src={getcardLogoForProduct(hoveredItem.projectTitle)} alt={hoveredItem.projectTitle} />
                                                <h2 className='m-0'>{hoveredItem.projectTitle}</h2>
                                            </div>
                                            <p>{hoveredItem.description}</p>
                                            <div className="buttons">
                                                <a href={hoveredItem.buttonLink} target='_blank' rel="noopener noreferrer"><button className="btn">Download</button></a>
                                                <Link to={hoveredItem.helpCenter} target='_blank'><button className="btn">Product Walkthrough</button></Link>
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    );
};

export default ProductOverview;
